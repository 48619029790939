@import './tokens/colors';
@import './tokens/transitions';
@import './typography';

@mixin input-usual {
  @include typography-h7;

  outline: none;
  border: 1px solid $color-grey;
  border-radius: 8px;
  padding: 12px 22px;
  width: 100%;
  height: 50px;
  color: $color-black;
  background-color: #fff;
  transition: border-color $transition-main, color $transition-main;

  &::placeholder {
    color: $color-grey;
  }

  &:focus {
    border-color: $color-original-blue;
    color: $color-original-blue;
  }
}

@mixin input-error {
  border-color: $color-red;
  color: $color-red;
}

@mixin input-disabled {
  background-color: $color-bg;
}