@import 'src/styles/tokens/colors';
@import 'src/styles/mixins';
@import 'src/styles/typography';

.MenuSubItemList {
  display: flex;
  flex-direction: column;
}

.MenuSubItemList__title {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  border-bottom: 1px solid $color-light-grey;
  padding-bottom: 10px;
  font-family: $font-family-secondary;
  color: $color-original-blue;
  cursor: pointer;
}

.MenuSubItemList__titleIcon {
  @include svg-icon;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  transform: rotate(90deg);
}

.MenuSubItemList__card {
  & + & {
    margin-top: 16px;
  }
}