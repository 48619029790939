@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/mixins';

.FooterMiddleList {
  color: #fff;
}

.FooterMiddleList__title {
  margin-bottom: 16px;
  font-weight: 500;
}

.FooterMiddleList__list {
  @include list-reset;
}

.FooterMiddleList__listItem {
  transition: color $transition-main;

  &:hover {
    color: $color-original-blue;
  }

  & + & {
    margin-top: 12px;
  }
}