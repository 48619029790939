@import 'src/styles/media';
@import 'src/styles/tokens/transitions';
@import 'src/styles/tokens/zIndex';
@import 'src/styles/tokens/colors';

.Header__outer {
  height: 80px;

  @include media-up(breakpoint-lg) {
    height: 90px;
  }
}

.Header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: $zindex-popover;
  border-bottom: 1px solid lighten($color-grey, 10%);
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: #fff;
  transition: padding 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  @include media-up(breakpoint-lg) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.Header_animated {
  top: 0;
  border-bottom: none;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 16%);

  @include media-up(breakpoint-lg) {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.Header__relative {
  position: relative;
}

.Header__inner {
  display: flex;
  align-items: center;
}

.Header__logo {
  display: none;

  @include media-up(breakpoint-lg) {
    display: block;
    margin-right: 52px;
  }

  @include media-up(breakpoint-xl) {
    margin-right: 100px;
  }
}

.Header__menu {
  display: none;

  @include media-up(breakpoint-lg) {
    display: block;
  }
}

.Header__mobileMenu {
  display: flex;

  @include media-up(breakpoint-lg) {
    display: none !important;
  }
}