@import 'src/styles/typography';

/* variants */

.Typography_h1 {
  @include typography-h1;
}

.Typography_h2 {
  @include typography-h2;
}

.Typography_h3 {
  @include typography-h3;
}

.Typography_h4 {
  @include typography-h4;
}

.Typography_h5 {
  @include typography-h5;
}

.Typography_h6 {
  @include typography-h6;
}

.Typography_h7 {
  @include typography-h7;
}

.Typography_h8 {
  @include typography-h8;
}

.Typography_h9 {
  @include typography-h9;
}

.Typography_caption {
  @include typography-caption;
}

.Typography_p1-f {
  @include typography-p1-f;
}

.Typography_p2-f {
  @include typography-p2-f;
}

.Typography_p3-f {
  @include typography-p3-f;
}

.Typography_h1-f {
  @include typography-h1-f;
}

.Typography_h2-f {
  @include typography-h2-f;
}

.Typography_h3-f {
  @include typography-h3-f;
}

.Typography_h4-f {
  @include typography-h4-f;
}
