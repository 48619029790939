@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/media';

.HeaderActions {
  align-items: center;
  margin-left: auto;
}

.HeaderActions_desktop {
  display: none;

  @include media-up(breakpoint-md) {
    display: flex;
  }
}

.HeaderActions_mobile {
  display: flex;

  @include media-up(breakpoint-md) {
    display: none;
  }
}

.HeaderActions__action {
  & + & {
    margin-left: 30px;
  }
}

.HeaderActions__btn {
  padding: 12px 44px;
  font-weight: 400;
  font-size: 18px;
  height: auto;
} 