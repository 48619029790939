@mixin svg-icon {
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@mixin button-reset {
  appearance: none;
  overflow: hidden;
  margin: 0;
  outline: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  color: inherit;
  background: none;
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }
}

@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin link-reset {
  text-decoration: none;
  color: inherit;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }
}

@mixin text-overflow($linesAmount: 1) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if $linesAmount > 1 {
    /* stylelint-disable-next-line */
    display: -webkit-box;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $linesAmount;
    line-clamp: $linesAmount;
  } @else {
    white-space: nowrap;
  }
}

@mixin scrollbar-small {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    border-radius: 20px;
    background-clip: content-box;
    background-color: $color-grey;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $color-grey;
  }
}