@import 'src/styles/tokens/transitions';
@import 'src/styles/tokens/colors';
@import 'src/styles/typography';

.MenuUsualItem {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-family: $font-family-secondary;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  transition: color $transition-main;
  cursor: pointer;

  a {
    display: block;
    width: 100%;
  }

  &:hover {
    color: $color-original-blue;
  }
}