@import 'src/styles/mixins';
@import 'src/styles/media';

.FooterTop {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 54px;
  }
}

.FooterTop__icon {
  @include svg-icon;

  margin-bottom: 26px;
  width: 100px;
  height: 30px;
  color: #fff;

  @include media-up(breakpoint-md) {
    margin-bottom: 0;
    width: 200px;
    height: 60px;
  }
}

.FooterTop__form {
  @include media-up(breakpoint-md) {
    display: flex;
    align-items: center;
  }
}

.FooterTop__input {
  @include media-up(breakpoint-md) {
    margin-right: 20px;
    max-width: 424px;
  }

  @include media-up(breakpoint-lg) {
    margin-right: 28px;
    width: 424px;
  }
}

.FooterTop__btn {
  margin-top: 20px;
  width: 100%;
  padding: 12px 44px;
  font-weight: 400;
  font-size: 18px;
  height: auto;

  @include media-up(breakpoint-md) {
    margin-top: 0;
    width: auto;
  }
}