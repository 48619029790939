@import 'src/styles/media';

.ContentSection_margin {
  margin-top: 60px;

  @include media-up(breakpoint-md) {
    margin-top: 80px;
  }

  @include media-up(breakpoint-lg) {
    margin-top: 110px;
  }
}

.ContentSection_padding {
  padding-top: 60px;

  @include media-up(breakpoint-md) {
    padding-top: 80px;
  }

  @include media-up(breakpoint-lg) {
    padding-top: 110px;
  }
}
