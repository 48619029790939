@import 'src/styles/tokens/transitions';
@import 'src/styles/tokens/colors';
@import 'src/styles/typography';
@import 'src/styles/mixins';

.MenuButtonItem {
  border-bottom: 1px solid transparent;
  padding: 10px 0;
  font-family: $font-family-secondary;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  transition: color $transition-main, border-color $transition-main;
  cursor: pointer;

  a {
    display: block;
    width: 100%;
  }

  button {
    @include button-reset;
  }

  &:hover {
    color: $color-original-blue;
  }
}

.MenuButtonItem_icon {
  @include svg-icon;

  margin-left: auto;
  width: 28px;
  height: 28px;
  transition: transform $transition-main;
}

.MenuButtonItem_active {
  border-color: $color-light-grey;
  color: $color-original-blue;

  .MenuButtonItem_icon {
    transform: rotate(-180deg);
  }
}

.MenuButtonItem__top {
  display: flex;
  align-items: center;
}

.MenuButtonItem__subList {
  @include list-reset;

  margin-top: 10px;
  border-top: 1px solid $color-light-grey;
}

.MenuButtonItem__subListItem {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  color: $color-dark-grey;
  transition: color $transition-main;

  &:first-of-type {
    margin-top: 8px;
  }

  &:hover {
    color: $color-original-blue;
  }
}

.MenuButtonItem__chevron {
  margin-left: 12px;
  width: 32px;
  height: 32px;

  svg {
    transform: rotate(-90deg);
  }
}