@import 'src/styles/media';

.HeaderMobileMenu {
  align-items: center;
}

.HeaderMobileMenu__logo {
  margin-left: 10px;

  @include media-up(breakpoint-md) {
    margin-left: 26px;
  }
}