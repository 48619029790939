@import 'src/styles/media';

.FooterMiddle {
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;

  @include media-up(breakpoint-lg) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 74px;
  }
}

.FooterMiddle__lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media-up(breakpoint-md) {
    flex-wrap: nowrap;
  }

  @include media-up(breakpoint-xl) {
    flex-grow: 1;
    margin-right: 22%;
  }
}

.FooterMiddle__list {
  width: calc(50% - 5%);

  &:nth-child(3),
  &:nth-child(4) {
    margin-top: 36px;
  }

  @include media-up(breakpoint-md) {
    width: auto;

    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 0;
    }

    & + & {
      margin-left: 32px;
    }
  }

  @include media-up(breakpoint-xl) {
    & + & {
      margin-left: 0;
    }
  }
}