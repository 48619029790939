@import 'src/styles/mixins';

.Icon {
  @include button-reset;
  @include svg-icon;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
}