@import 'src/styles/mixins';
@import 'src/styles/media';

.FooterMiddleContacts {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    margin-left: auto;
    width: fit-content;
  }

  @include media-up(breakpoint-lg) {
    flex-direction: column;
    margin-top: 0;
    margin-left: unset;
    width: auto;
  }
}

.FooterMiddleContacts__contact {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 20px;
  }

  @include media-up(breakpoint-md) {
    & + & {
      margin-top: 0;
      margin-left: 90px;
    }
  }

  @include media-up(breakpoint-lg) {
    & + & {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}

.FooterMiddleContacts__title {
  margin-right: 20px;
  width: 72px;
  font-weight: 500;
  color: #fff;

  @include media-up(breakpoint-xl) {
    margin-right: 32px;
  }
}

.FooterMiddleContacts__social {
  @include list-reset;

  display: flex;
  align-items: center;
}

.FooterMiddleContacts__socialIcon {
  & + & {
    margin-left: 16px;
  }
}

.FooterMiddleContacts__socialIconLink {
  @include svg-icon;

  display: block;
  width: 32px;
  height: 32px;
}

.FooterMiddleContacts__emailLink {
  display: flex;
  align-items: center;
  cursor: pointer;
}