@import 'src/styles/inputs';

.Input__wrapper {
  position: relative;
}

.Input {
  @include input-usual;
}

.Input_error {
  @include input-error;
}

.Input_disabled {
  @include input-disabled;

  color: $color-grey;
}

.Input_startIcon {
  padding-left: 48px;
}

.Input_endIcon {
  padding-right: 48px;
}

/* Icon */

.Input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Input__icon_start {
  left: 16px;

  @extend .Input__icon;
}

.Input__icon_end {
  right: 16px;

  @extend .Input__icon;
}