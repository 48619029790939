@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';

.TooltipMenuHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 14px 22px;
  color: $color-dark-grey;
  transition: color $transition-main, background-color $transition-main;
  cursor: pointer;

  &:not(.TooltipMenuHeading_active):hover {
    color: $color-original-blue;
    background-color: $color-bg;
  }
}

.TooltipMenuHeading_active {
  color: $color-original-blue;
  background-color: $color-bg;
}

.TooltipMenuHeading__text {
  margin-right: 12px;
}

.TooltipMenuHeading__icon {
  svg {
    width: 22px;
    height: 22px;
    transform: rotate(-90deg);
  }
}