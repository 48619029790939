@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/mixins';

.TextButton {
  @include button-reset;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: color $transition-main;
}

.TextButton_disabled {
  pointer-events: none;
}

/* variants */

.TextButton_primary {
  color: $color-original-blue;

  &:hover {
    color: $color-green;
  }

  &:active {
    color: $color-dark-blue;
  }
}

.TextButton_secondary {
  color: $color-dark-grey;

  &:hover {
    color: $color-green;
  }

  &:active {
    color: $color-original-blue;
  }
}

/* icons */

.TextButton__icon {
  width: 20px;
  height: 20px;
  color: inherit;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.TextButton__iconLeft {
  margin-right: 10px;
}

.TextButton__iconRight {
  margin-left: 10px;
}