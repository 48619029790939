@import 'src/styles/tokens/zIndex';

.MobileMenuBlackout {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $zindex-offcanvas;
  background: rgb(2 8 64 / 55%);
  cursor: pointer;
}