@import 'src/styles/tokens/colors';

.FooterBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-grey;
  padding-top: 16px;
  color: #fff;
}

.FooterBottom__address {
  text-transform: uppercase;
}