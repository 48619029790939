@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.Footer {
  margin-top: 132px;
  padding-top: 18px;
  padding-bottom: 40px;
  background-color: $color-bg-deep-blue;

  @include media-up(breakpoint-md) {
    padding-top: 32px;
    padding-bottom: 24px;
  }

  @include media-up(breakpoint-lg) {
    padding-top: 48px;
  }
}

.Footer__inner {
  display: flex;
  flex-direction: column;
}