@import 'src/styles/tokens/zIndex';
@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.MobileMenuDrawer {
  position: fixed;
  left: 0;
  top: 72px;
  bottom: 0;
  z-index: $zindex-tooltip;
  overflow-y: auto;
  border-top: 1px solid $color-grey;
  padding: 34px 16px;
  width: 100vw;
  background-color: #fff;

  @include media-up(breakpoint-sm) {
    border-top: 1px solid transparent;
    padding: 42px 24px;
    width: 80vw;
    max-width: 465px;
  }
}