@import 'src/styles/tokens/transitions';
@import 'src/styles/tokens/colors';
@import 'src/styles/mixins';

.MobileMenuItems {
  @include list-reset;
}

.MobileMenuItems__item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparent;
  padding: 10px 0;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  transition: color $transition-main, border-color $transition-main;
  cursor: pointer;

  a {
    display: block;
    width: 100%;
  }

  &:hover {
    color: $color-original-blue;
  }
}

.MobileMenuItems__item_button {
  button {
    @include button-reset;
  }
}

.MobileMenuItems__itemIcon {
  @include svg-icon;

  margin-left: auto;
  width: 28px;
  height: 28px;
  transition: transform $transition-main;
}

.MobileMenuItems__item_active {
  border-color: $color-light-grey;
  color: $color-original-blue;

  .MobileMenuItems__itemIcon {
    transform: rotate(-180deg);
  }
}