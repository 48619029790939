@import 'src/styles/media';

.TooltipMenuDomains {
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;

  @include media-up(breakpoint-xl) {
    column-gap: 22px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 22px;
  }
}