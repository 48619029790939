@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/mixins';

.SkillCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 14px 22px;
  color: $color-dark-grey;
  background: $color-bg;
  transition: background-color $transition-main, color $transition-main, transform $transition-main;

  &:hover {
    color: #fff;
    background-color: $color-original-blue;
    transform: scale(1.01);
  }
}

.SkillCard__title {
  display: flex;
  align-items: center;
}

.SkillCard__icon {
  margin-top: 2px;
  margin-right: 12px;
}

.SkillCard__arrow {
  @include svg-icon;

  width: 18px;
  height: 18px;
}