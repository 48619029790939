@import 'src/styles/mixins';
@import 'src/styles/media';
@import 'src/styles/typography';
@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';

.HeaderMenu__inner {
  @include list-reset;

  display: flex;
  align-items: center;
}

.HeaderMenu__item {
  color: $color-dark-grey;
  transition: color $transition-main;
  cursor: pointer;

  &:hover,
  a:focus,
  button:focus {
    color: $color-original-blue;

    svg {
      color: $color-original-blue;
    }
  }

  & + & {
    margin-left: 42px;
  }

  @include media-up(breakpoint-xl) {
    & + & {
      margin-left: 70px;
    }
  }
}

.HeaderMenu__item_button {
  button {
    @include button-reset;
    @include typography-h7;
  }
}

.HeaderMenu__icon {
  margin-left: 4px;
  transition: transform $transition-main;
}

.HeaderMenu__icon_hovered {
  transform: rotate(-180deg);
}