@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/mixins';

.DomainCard {
  display: block;
  border-radius: 20px;
  padding: 14px 24px;
  color: $color-dark-grey;
  background-color: $color-bg;
  transition: background-color $transition-main, color $transition-main, transform $transition-main;

  &:hover {
    color: #fff;
    background-color: $color-original-blue;
    transform: scale(1.01);
  }
}

.DomainCard__icon {
  @include svg-icon;

  width: 46px;
  height: 24px;
}

.DomainCard__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  white-space: nowrap;
}

.DomainCard__arrow {
  @include svg-icon;

  margin-left: 16px;
  width: 18px;
  height: 18px;
}