@import 'src/styles/tokens/colors';
@import 'src/styles/typography';

.FieldContainer__label {
  // @include typography-p3;

  margin-bottom: 4px;
  width: fit-content;
  color: $color-dark-grey;
  cursor: pointer;
}

.FieldContainer__withMargin {
  margin-bottom: 16px;
}

.FieldContainer__helpText {
  // @include typography-caption;

  display: flex;
  align-items: center;
  margin-top: 4px;
  width: fit-content;
  color: $color-dark-grey;
  cursor: pointer;
}

.FieldContainer__error {
  color: $color-red;
}

.FieldContainer__icon {
  margin-right: 10px;

  svg {
    width: 18px;
    height: 18px;
  }
}