@import './media';


@mixin typography-h1 {
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  line-height: 48px;

  @include media-up(breakpoint-md) {
    font-size: 70px;
    line-height: 92px;
  }
}

@mixin typography-h2 {
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-style: normal;
  font-size: 26px;
  line-height: 36px;

  @include media-up(breakpoint-md) {
    font-size: 52px;
    line-height: 72px;
  }
}

@mixin typography-h3 {
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 32px;

  @include media-up(breakpoint-md) {
    font-size: 40px;
    line-height: 56px;
  }
}

@mixin typography-h4 {
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;

  @include media-up(breakpoint-md) {
    font-size: 26px;
    line-height: 36px;
  }
}

@mixin typography-h5 {
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;

  @include media-up(breakpoint-md) {
    font-size: 22px;
    line-height: 32px;
  }
}

@mixin typography-h6 {
  font-family: $font-family-main, $font-family-default;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  @include media-up(breakpoint-md) {
    font-size: 22px;
    line-height: 32px;
  }
}

@mixin typography-h7 {
  font-family: $font-family-main, $font-family-default;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}

@mixin typography-h8 {
  font-family: $font-family-main, $font-family-default;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

@mixin typography-h9 {
  font-family: $font-family-main, $font-family-default;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin typography-caption {
  font-family: $font-family-main, $font-family-default;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin typography-h1-f {
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  @include media-up(breakpoint-md) {
    font-size: 36px;
    line-height: 48px;
  }
}

@mixin typography-h2-f {
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
 
  @include media-up(breakpoint-md) {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin typography-h3-f {
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @include media-up(breakpoint-md) {
    font-size: 22px;
    line-height: 32px;
  }
}

@mixin typography-h4-f {
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @include media-up(breakpoint-md) {
    font-size: 20px;
    line-height: 28px;
  }
}

@mixin typography-p1-f {
  font-family: $font-family-main, $font-family-default;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @include media-up(breakpoint-md) {
    font-size: 18px;
    line-height: 24px;
  }
}

@mixin typography-p2-f {
  font-family: $font-family-main, $font-family-default;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  @include media-up(breakpoint-md) {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin typography-p3-f {
  font-family: $font-family-main, $font-family-default;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}



$font-family-main: 'Roboto';
$font-family-secondary: 'Cabin';
$font-family-default:
  system-ui, /* macOS 10.11-10.12 */ -apple-system, /* Windows 6+ */ 'Segoe UI',
  /* Android 4+ */ 'Roboto', /* Ubuntu 10.10+ */ 'Ubuntu', /* Gnome 3+ */ 'Cantarell', /* KDE Plasma 5+ */ 'Noto Sans',
  /* fallback */ sans-serif, /* macOS emoji */ 'Apple Color Emoji', /* Windows emoji */ 'Segoe UI Emoji',
  /* Windows emoji */ 'Segoe UI Symbol', /* Linux emoji */ 'Noto Color Emoji';