@import 'src/styles/tokens/zIndex';
@import 'src/styles/media';

.TooltipMenu {
  position: absolute;
  left: 0;
  right: 0;
  top: 128%;
  z-index: $zindex-tooltip;
  padding: 38px 0;
  box-shadow: 0 5px 10px -4px rgb(0 0 0 / 16%);
  background-color: #fff;
}

.TooltipMenu__inner {
  display: flex;
}

.TooltipMenu__block {
  &:first-of-type {
    width: 328px;
  }

  &:last-of-type {
    flex-grow: 1;
    padding-left: 32px;

    @include media-up(breakpoint-xl) {
      padding-left: 56px;
    }
  }
}

.TooltipMenu__heading {
  & + & {
    margin-top: 16px;
  }
}